import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import { Container, PostContainer, Meta } from './styles'
import ContentContainer from '../../components/ContentContainer'
import HeroSvg from '../../components/HeroSvg/index'

export default function Template ({ data, pageContext }) {
	const { markdownRemark: { frontmatter, html, excerpt } } = data
	
	const seoProps = {
		title: frontmatter.title,
		frontmatter,
		excerpt
	}

	if (pageContext.seoImageUrl) {
		seoProps.imageUrl = pageContext.seoImageUrl
	}

	return (
		<Layout>
			<SEO {...seoProps} />

			<Container>
				{
					pageContext.svg
						? <HeroSvg rawSvg={pageContext.svg} />
						: frontmatter.heroSvg
							? <HeroSvg>
								<img src={frontmatter.heroSvg.publicURL} alt="" />
							</HeroSvg>
							: null
				}

				<ContentContainer>
					<h1>{frontmatter.title}</h1>
					<PostContainer dangerouslySetInnerHTML={{ __html: html }}/>
					<Meta>{frontmatter.date}</Meta>
				</ContentContainer>
			</Container>
		</Layout>
	)
}

export const pageQuery = graphql`
	query($path: String!) {
		markdownRemark(frontmatter: { path: { eq: $path } }) {
			html
			excerpt
			frontmatter {
				date(formatString: "MMMM DD, YYYY")
				path
				title
				heroSvg {
					publicURL
				}
			}
		}
	}
`
