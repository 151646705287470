import styled from 'styled-components'

export const Container = styled.div``

export const PostContainer = styled.div`
	margin-bottom: 1rem;
`

export const Meta = styled.div`
	opacity: 0.5;
`
