import styled from 'styled-components'

export const Container = styled.div`
	width: calc(100% + 15rem);
	display: flex;
	align-items: center;
	justify-content: center;
	height: 20rem;
	margin-bottom: 1.5rem;
	margin-left: -7.5rem;
	position: relative;
`
