import React from 'react'
import { Container } from './styles'
import StaggeredSvg from '../StaggeredSvg/index'

export default function HeroSvg (props) {
	return (
		<Container role="presentation">
			<StaggeredSvg {...props} />
		</Container>
	)
}
